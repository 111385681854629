import React from 'react';
import { useStaticQuery, graphql, StaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { HeaderStyle } from '../styles/Header';
import { H1Component, ButtonComponent } from '../styles/StyledComponents';

function Header({ image, text, hasButton, buttonText }) {
  return (
    <HeaderStyle>
      <BackgroundImage
        Tag="section"
        style={{
          height: '450px',
          borderBottom: '2px solid #292929',
        }}
        fluid={image}
        backgroundColor="#040e18"
      />
      <div className="content">
        <H1Component>{text}</H1Component>
        {hasButton ? 
        <Link to="/contact">
        <ButtonComponent>{buttonText}</ButtonComponent>
        </Link>
         : null}
      </div>
    </HeaderStyle>
  );
}

export default Header;
