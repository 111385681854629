import styled from 'styled-components';

export const HeaderStyle = styled.div`
  position: relative;
  height: 450px;

  .content {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    button {
      margin-top: 15px;
    }
  }
`;
